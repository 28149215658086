import { render, staticRenderFns } from "./warning_wrap.vue?vue&type=template&id=7aac932a"
import script from "./warning_wrap.vue?vue&type=script&lang=js"
export * from "./warning_wrap.vue?vue&type=script&lang=js"
import style0 from "./warning_wrap.vue?vue&type=style&index=0&id=7aac932a&lang=less"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home2/staoush/public_sam/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7aac932a')) {
      api.createRecord('7aac932a', component.options)
    } else {
      api.reload('7aac932a', component.options)
    }
    module.hot.accept("./warning_wrap.vue?vue&type=template&id=7aac932a", function () {
      api.rerender('7aac932a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "sites/default/js/components/assessment/elements/warning_wrap.vue"
export default component.exports